<template>
    <div>
        <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'" id="printTest" style="height: 100vw">
            <el-row >
                <el-col :span="12">
                    <div id="homequanb" style="width: 600px; margin: auto;height: 25vw;"></div>
                </el-col>
                <el-col :span="12">
                    <div id="homedaoqi" style="width: 600px; margin: auto;height: 25vw;"></div>
                </el-col>
            </el-row>
            <el-row >
                <el-col :span="24">
                    <el-button size="small" @click="Getmeiyue('Getmeiyue')" type="text">{{$t('All.总览')}}</el-button>
                    <el-button size="small" @click="Getmeiyue('Getmeiyuechangqu?changqu=TC')"  type="text">{{$t('All.太仓厂')}}</el-button>
                    <el-button size="small" @click="Getmeiyue('Getmeiyuechangqu?changqu=ZH')"  type="text">{{$t('All.珠海厂')}}</el-button>
                    <el-button size="small" @click="Getmeiyue('Getmeiyuetype?type=FQC')"  type="text">FQC</el-button>
                    <el-button size="small" @click="Getmeiyue('Getmeiyuetype?type=IQC')"  type="text">IQC</el-button>
                    <el-button size="small" @click="Getmeiyue('Getmeiyuetype?type=OQC')"  type="text">OQC</el-button>
                    <el-button size="small" @click="Getmeiyue('Getmeiyuetype?type=IPQC')"  type="text">IPQC</el-button>
                    <div id="tibiao" style="width: 100%; margin: auto;height: 18vw;"></div>
                </el-col>
            </el-row>
        </div>
    </div>
  </template>
<script>
export default {
  data () {
    return {
      isqpisboot: false,
      // 全部数据
      homequanb: {
        title: {
          text: 'Nienyi-QC',
          subtext: this.$t('All.系统现有数据'),
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: this.$t('All.现有数据'),
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'MSDS' },
              { value: 735, name: this.$t('All.检验设备') },
              { value: 580, name: this.$t('All.标准样品') }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      // 指定图表的配置项和数据
      homedaoqi: {
        title: {
          text: 'Nienyi-QC',
          subtext: this.$t('All.未结案统计'),
          left: 'center'
        },
        tooltip: {},
        legend: {
          data: ['FQC', 'IQC', 'OQC', 'IPQC']
        },
        xAxis: {
          data: ['FQC', 'IQC', 'OQC', 'IPQC']
        },
        yAxis: {},
        series: [
          {
            name: this.$t('All.未结案'),
            type: 'bar',
            data: [5, 20, 36]
          }
        ]
      },
      shiyongzt: {
        title: {
          text: 'Nienyi-QC UsageLine'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['FQC', 'IQC', 'OQC', 'IPQC']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          name: this.$t('All.月份'),
          type: 'category',
          boundaryGap: false,
          data: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'MSDS',
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: this.$t('All.检验设备'),
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: this.$t('All.标准样品'),
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 110, 0, 0, 0]
          },
          {
            name: this.$t('All.标准样品'),
            type: 'line',
            stack: 'Total',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 110, 0, 0, 0]
          }
        ]
      }
    }
  },
  components: {
  },
  created () {
  },
  mounted () {
    this.Getdata()
    this.Getdaoqidata()
    this.Getmeiyue('Getmeiyue')
  },
  methods: {
    async Getdata () {
      try {
        const { data: res } = await this.$http.get('/api/Authenticate/Getdata')
        if (res.status !== 200) return this.$message.error(res.msg)
        const myChart = this.$echarts.init(document.getElementById('homequanb'))
        window.onresize = function () {
          myChart.resize()
        }
        this.homequanb.series[0].data = res.response
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.homequanb)
      } catch (error) {
        return this.$message.error(error)
      }
    },
    async Getdaoqidata () {
      try {
        const { data: res } = await this.$http.get('/api/Authenticate/Getdaoqidata')
        if (res.status !== 200) return this.$message.error(res.msg)
        // 基于准备好的dom，初始化echarts实例
        const myChart = this.$echarts.init(document.getElementById('homedaoqi'))
        window.onresize = function () {
          myChart.resize()
        }
        this.homedaoqi.series[0].data = res.response
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.homedaoqi)
      } catch (error) {
        return this.$message.error(error)
      }
    },
    async Getmeiyue (row) {
      try {
        const { data: res } = await this.$http.get(`/api/Authenticate/${row}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        const myChart = this.$echarts.init(document.getElementById('tibiao'))
        window.onresize = function () {
          myChart.resize()
        }
        this.shiyongzt.series = res.response
        this.shiyongzt.legend.data = res.name
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.shiyongzt)
      } catch (error) {
        return this.$message.error(error)
      }
    }
  }
}
</script>
  <style lang="less" scoped>
    .wbobye{
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255);
  }
  </style>
